<script>
import { mapGetters } from 'vuex';

var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("topnav").style.top = "0";
  } else {
    document.getElementById("topnav").style.top = "-60px";
  }
  prevScrollpos = currentScrollPos;
}

export default {
  name: 'TopNav',
  computed: {
    ...mapGetters({
      menuOpen: 'MENU_OPEN'
    })
  }
}
</script>

<template>
  <nav id="topnav">
    <div class="menu-title">
      <div class="logo" @click="$router.go('/')">
        <img src="@/assets/logos/dark-logo.png" width="40" height="40" />
        <h5>Maobe Mukhwana & Co. Advocates</h5>
      </div>
      <div class="toggler">
        <img v-if="!menuOpen" src="@/assets/logos/menu.png" width="35" height="30" @click="$emit('toggleMenu', true)" />
        <img v-else src="@/assets/logos/close.png" width="35" height="30" @click="$emit('toggleMenu', false)" />
      </div>
    </div>
    <ul id="nav-items">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/team">Our Team</router-link>
      </li>
      <li>
        <router-link to="/services">Services</router-link>
      </li>
      <!-- <li>
        <router-link to="/blogs">Blogs</router-link>
      </li> -->
      <li>
        <router-link to="/contact">Contact Us</router-link>
      </li>
    </ul>
  </nav>
</template>

<style>
nav#topnav{
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: linear-gradient(to bottom right, #303030d5, #333333e8);
  transition: all calc(var(--transition-time) / 2 ) ease;
  &:hover {
    background-color: #302f2f;
  }
}
nav > div.menu-title > div.logo{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-inline: .5rem auto;
  color: antiquewhite
}
nav > div.menu-title > div.toggler{
  display: none;
}
nav > ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  padding: 0;
  li {
    display: block;
    margin: .2rem .5rem;
    a {
      color: rgb(209, 209, 209);
      transition: all 150ms ease-in;
      font-weight: 600;
    }
    a:hover {
      color: rgb(126, 250, 137);
    }
    a.router-link-active.router-link-exact-active {
      color: white;
    }
  }
}
@media (420px < width <= 720px) {
  nav > div.menu-title > .logo > h5 {
    display: none;
  }
  /* nav > div.menu-title > div.toggler{
    display: block;
  } */
}
@media (width <= 420px) {
  nav#topnav {
    padding: 0;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  nav#topnav > div.menu-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    
    .logo {
      display: inline-flex;
      align-items: center;
      color: rgb(209, 209, 209);
      img {
        margin-inline-end: .2rem;
      }
    }

    .toggler img:hover {
      outline: 5px white groove;
      cursor: pointer;
    }
  }
  ul {
    background-color: #333333e8;
    width: 100%;
    margin: 0;
    padding: 0 !important;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: -100%;
    transition: all var(--transition-time) ease;
    li {
      width: 100%;
      a {
        display: block;
        padding-block: .2rem ;
        &:hover {
          color: rgb(126, 250, 137) !important;
          background-color: black !important;
        }
      }
    }
    &.open {
      right: 0;
    }
  }
}
</style>