<script>
export default {
    name: 'FooterSection'
}
</script>

<template>
    <footer>
      <div class="footer">
        <div class="footer__brand fade-in slide-in">
          <div class="logo">
            <picture>
              <source srcset="@/assets/logos/dark-logo.png" media="(prefers-color-scheme: dark)" alt="Maobe Mukhwana & Company" width="100" height="100" class="fade-in" />
              <img src="@/assets/logos/logo.png" alt="Maobe Mukhwana & Company" width="100" height="100" class="fade-in" />
            </picture>
            <h2 class="finlandica-bold">Maobe Mukhwana & Company Advocates</h2>
          </div>
        </div>
        <div class="footer__office fade-in slide-in">
          <div class="address">
            <p><b>Main Office</b></p>
            <p>2nd Floor, Suite No.205</p>
            <p>Walias Center, Kakamega</p>
            <p>Along the Kakamega-Kisumu Highway</p>
          </div>
          <div class="address">
              <p><b>Kimilili Office</b></p>
              <p>Alimo Investment House</p>
              <p>Near The District Commissioner's Office, Kimilili.</p>
              <!-- <p class="fade-in slide-in">Along the Kakamega-Kisumu Highway</p> -->
          </div>
        </div>
        <div class="footer__contact fade-in slide-in">
          <h2>CONTACT US</h2>
          <div class="address">
            <p class=""><b>Mobile: </b> <a href="tel: +254700270464"> +254700270464</a></p>
            <p><b>Email: </b> <a href="mailto: info@maobemukhwanaadvocates.com"> info@maobemukhwanaadvocates.com</a></p>
            <!-- <p class="fade-in slide-in"><b>Social:</b> @reallygreatsite</p> -->
          </div>
          <button @click="openModal" >BOOK A CONSULTATION</button>
        </div>
      </div>
      <div class="copy">
        <a href="mailto: obwatsamd@gmail.com">Contact Support</a>
        <a href="https://bysonicgroup.com">Designed by BySonic&copy;</a></div>
    </footer>
</template>

<style>
footer, div.copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding: 1rem 2rem; */
  background: black;
  color: antiquewhite;
}
footer {
  flex-direction: column;
}
footer a {
  color: antiquewhite;
  text-decoration: none;
  margin: 1rem 2rem;
}
.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, min(25rem, 100%));
  justify-content: space-around;
  align-items: start;
  gap: 1rem;
  width: 100%;
}
.footer__brand {
  margin-block: auto;
  align-items: center;
  justify-content: center;
}
.footer__office, .footer__contact{
  padding-block: 1rem;
  align-items: start;
  justify-content: center;
  text-align: start;
}
.address {
  margin-block: 1rem;
  p {
    margin-block: 0;
    padding-block: 0;
  }
}
@media (width < 641px) {
  .footer > * {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
  }
}
</style>